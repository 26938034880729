import React from 'react';
import { Link } from 'react-scroll';
import '../assets/css/navbar.css';

const Navbar = () => {
    return (
        <nav className="navbar">
            <div className="left-text gradient-text">Jeremy McHatton</div>
            <div className="nav-links">
                <Link to="about" smooth={true} duration={1000} className="link">About</Link>
                <Link to="skills" smooth={true} duration={1000} className="link">Skills</Link>
                <Link to="projects" smooth={true} duration={1000} className="link">Projects</Link>
                <Link to="contact" smooth={true} duration={1000} className="link">Contact</Link>
            </div>
        </nav>
    );
};

export default Navbar;