import React from 'react';
import '../assets/css/contact.css';

const Footer = () => {
    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    };

    return (
        <footer id="contact" className="footer">
            <p>Contact me:</p>
            <button className="contactButton" onClick={() => window.location.href="mailto:jeremymchatton@gmail.com"}>
                Email
            </button>
            <button className="contactButton" onClick={() => window.open("/resume.pdf", "_blank")}>
                Résumé
            </button>
            <button className="contactButton" onClick={() => window.open("https://www.linkedin.com/in/jeremy-mchatton-340906218", "_blank")}>
                LinkedIn
            </button>
            <button className="contactButton" onClick={() => window.open("https://www.github.com/Jer542/", "_blank")}>
                GitHub
            </button>
            <button className="scrollToTopButton" onClick={scrollToTop}>
                &#x25B2;
            </button>
            <p>&copy; Jeremy McHatton 2024</p>
        </footer>
    );
}

window.onscroll = function() {
    const button = document.querySelector('.scrollToTopButton');
    if (button) {
        if (document.body.scrollTop > 20 || document.documentElement.scrollTop > 20) {
            button.style.display = "block";
        } else {
            button.style.display = "none";
        }
    }
};

export default Footer;