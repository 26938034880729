import React from 'react';
import '../assets/css/education.css';
import { Fade } from 'react-awesome-reveal';

function Education() {
  return (
    <div id="education">
      <h2>Education</h2>
      <div className="education-container">
        <div className="education-card">
          <Fade>
            <h3>The State University of New York at Canton</h3>
            <h4>BSc Cybersecurity</h4>
            <p>NSA CAE-CD Accredited</p>
          </Fade>
        </div>
      </div>
    </div>
  );
}

export default Education;