import React from 'react';
import profileImage from '../assets/jeremy.jpg';
import '../assets/css/profile.css';

const Profile = () => {
    return (
        <div id="about" className="about">
            <div className="profileImageContainer">
                <img src={profileImage} alt="Profile" className="profileImage" />
                <div className="separator"></div>
            </div>
            <div className="textContainer">
            <h1 className="heading">Welcome! I'm Jeremy, a passionate technologist based in New York.</h1>
            <p className="paragraph">With a strong foundation in cybersecurity, I bring a unique perspective to the realm of technology. My expertise spans various domains including Python, AWS, SQL, and DevOps, enabling me to architect robust, secure, and scalable solutions.</p>
            <p className="paragraph">Over the years, I've refined my skills to excel in both front-end and back-end technologies, making me a versatile asset in any technological endeavor. I'm enthusiastic about harnessing these skills to drive organizational success and craft impactful digital solutions.</p>
            <p className="paragraph">Beyond coding, I thrive on staying informed about the latest cybersecurity advancements and actively contributing to the tech community.</p>
            <p className="paragraph">Feel free to explore my website to discover more about my projects and experiences. Whether you're interested in collaboration or simply want to connect, I'm always open to discussing our shared passion for technology!</p>
            <p className="paragraph">Let's innovate together and create something remarkable. 🚀</p>
            </div>
        </div>
    );
}

export default Profile;