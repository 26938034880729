import React, { useState } from 'react';
import '../assets/css/skills.css';
import { Fade } from 'react-awesome-reveal'; 

const Skills = () => {
    const skills = [
        { category: 'Networking', skills: ['OSI Model', 'TCP/IP', 'UDP', 'TLS', 'Cisco Routers', 'Cisco Switches', 'Network Administration'] },
        { category: 'Ethical Hacking', skills: ['Kali Linux', 'Parrot OS', 'NMAP', 'BurpSuite', 'Metasploit', 'Cain and Abel', 'Hashcat', 'John the Ripper', 'Pentesting Tools'] },
        { category: 'Languages', skills: ['Python', 'Javascript', 'HTML', 'CSS', 'MySQL', 'PHP', 'BASH Scripting', 'PowerShell Scripting'] },
        { category: 'Services + Frameworks', skills: ['AWS', 'Docker', 'React', 'Flask', 'Django', 'Apache HTTP Server', 'Tornado'] },
        { category: 'Operating Systems + Other and Tools', skills: ['Windows', 'Windows Server', 'Linux', 'MacOS', 'Office 365', 'PuTTy', 'Wireshark', 'VMware', 'VirtualBox', 'Rufus', 'Balena Etcher'] },
        { category: 'Cybersecurity', skills: ['Cryptology', 'Incident Response', 'Disaster Recovery', 'Digital Forensics', 'Malware Analysis', 'Reverse Engineering'] },
    ];

    const [selectedSkill, setSelectedSkill] = useState(null);

    const handleClick = (index) => {
        setSelectedSkill(selectedSkill === index ? null : index);
    };

    const handleClose = (e) => {
        e.stopPropagation();
        setSelectedSkill(null);
    };

    return (
        <div id="skills">
            <h2>Skills</h2>
            <div className="skills-container">
                {skills.map((category, index) => (
                    (selectedSkill === null || selectedSkill === index) && (
                        <div key={index} onClick={() => handleClick(index)} className={`skill-card ${selectedSkill === index ? 'selected' : ''}`}>
                            <Fade>
                                {selectedSkill === index && <div onClick={handleClose} className="close-button">X</div>}
                                <h3>{category.category}</h3>
                                {selectedSkill === index ? 
                                    <p>{category.skills.join(', ')}</p> :
                                    <ul className="skill-list">
                                        {category.skills.map((skill, index) => (
                                            <li key={index}>
                                                {skill}
                                            </li>
                                        ))}
                                    </ul>
                                }
                            </Fade>
                        </div>
                    )
                ))}
            </div>
        </div>
    );
};

export default Skills;