import React, { useEffect, useState } from 'react';
import '../assets/css/projects.css';
import axios from 'axios'; 
import Prism from 'prismjs';
import 'prismjs/components/prism-python';
import 'prismjs/themes/prism-okaidia.min.css';
import { Fade } from 'react-awesome-reveal';

const Projects = () => {
    const projects = [
        { 
            title: 'Packet Sniffer', 
            description: 'A utilization of raw sockets and python to sniff packets and display the data in a readable format.', 
            link: 'https://github.com/Jer542/packet-sniffer',
            githubFilePath: '/packetsniffer.py'
        },
        { 
            title: 'Website Scanner', 
            description: 'A python program which scans for various different website info such as open ports, SSL cert info, DNS records, and more.', 
            link: 'https://github.com/Jer542/website-info-scanner',
            githubFilePath: '/scanner.py'
        },
        { 
            title: 'Hand Tracker', 
            description: 'Python OpenCV2 Hand Tracker which tracks the hand in real-time and calculates which fingers are held up on each hand', 
            link: 'https://github.com/Jer542/hand-tracker',
            githubFilePath: '/handtracker.py'
        },
        { 
            title: 'Facial Recognition', 
            description: 'Facial Recognition Python project using OpenCV2 and Haar Cascades to detect faces in real-time.', 
            link: 'https://github.com/Jer542/facial-recognition',
            githubFilePath: '/facialrecognition.py'
        },
        { 
            title: 'Epic Timer', 
            description: 'A general purpose timer application, written in python', 
            link: 'https://github.com/Jer542/epic-timer',
            githubFilePath: '/timer.py'
        },
        { 
            title: 'PassGen', 
            description: 'A python project that will generate a random strong password', 
            link: 'https://github.com/Jer542/password-generator',
            githubFilePath: '/passwordgen.py'
        },
    ];

    const [selectedProject, setSelectedProject] = useState(null);
    const [code, setCode] = useState('');

    useEffect(() => {
        if (selectedProject !== null) {
            const project = projects[selectedProject];
            const url = `https://api.github.com/repos/Jer542/${project.link.split('/').pop()}/contents/${project.githubFilePath}`;

            axios.get(url, {
                headers: { 'Accept': 'application/vnd.github.VERSION.raw' }
            })
            .then(response => {
                setCode(Prism.highlight(response.data, Prism.languages.python));
            })
            .catch(error => console.error(error));
        }
    }, [selectedProject]);

    const handleClick = (index) => {
        setSelectedProject(selectedProject === index ? null : index);
    };

    const handleClose = (e) => {
        e.stopPropagation();
        setSelectedProject(null);
    };

    const handleCodeClick = (e) => {
        e.stopPropagation();
    };

    return (
        <div id="projects">
            <h2 className="projects-title">Projects</h2>
            <div className="project-container">
                {projects.map((project, index) => (
                    (selectedProject === null || selectedProject === index) && (
                        <div onClick={() => handleClick(index)} className={`project ${selectedProject === index ? 'selected' : ''}`} key={index}>
                            <Fade>
                                {selectedProject === index && <div onClick={handleClose} className="close-button">X</div>}
                                <h3>{project.title}</h3>
                                <p>{project.description}</p>
                                {selectedProject === index && <pre onClick={handleCodeClick} className="code-block"><code className="language-python" dangerouslySetInnerHTML={{ __html: code }}></code></pre>}                            
                                {selectedProject === index && <a href={project.link} target="_blank" rel="noopener noreferrer" className="github-link">View on GitHub</a>}
                            </Fade>
                        </div>
                    )
                ))}
            </div>
        </div>
    );
};

export default Projects;